import React from "react";

const InsightsHeader = ({
  heading,
  paraOne,
  paraTwo,
  bgImageClass,
  textColor,
}) => {
  return (
    <div className={`section ${bgImageClass}`}>
      <div className="container">
        <div className="insights-header-section">
          <h1 className="heading">{heading}</h1>
          <div className="para m-top-20px">
            {paraOne} <br></br>
            {paraTwo}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightsHeader;
