import axios from "axios";
import { Link, navigate } from "gatsby";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import "./contactForm.scss";

const ContactForm = () => {
  const { register, handleSubmit } = useForm();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [load, setLoad] = useState(false);
  const [type, setType] = useState("General Inquiry");
  const onSubmit = (data) => {
    setLoad(true);
    setError("");
    setShowError(false);
    const { firstName, phone = "", email, msg } = data;

    // if (showTabOne) {
    //   type = "General Inquiry";
    // } else if (showTabTwo) {
    //   type = "Product Or Service Inquiry";
    // } else if (showTabThree) {
    //   type = "Career Inquiry";
    // } else if (showTabFour) {
    //   type = "Referral Inquiry";
    // }

    let server = "https://contactus.goken-global.com";
    const configs1 = {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    };
    const params = new URLSearchParams();
    // if (showTabFour) {
    //   if (attachment[0].type === "application/pdf") {
    //     if (location === "Your Work Location") {
    //       setError("Please Select Work Location");
    //       setShowError(true);
    //       setLoad(false);
    //     } else {
    //       const params2 = new FormData();
    //       params2.append("firstName", firstName);
    //       params2.append("lastName", lastName);
    //       params2.append("email", email);
    //       params2.append("firstNameRef", firstNameRef);
    //       params2.append("lastNameRef", lastNameRef);
    //       params2.append("emailRef", emailRef);
    //       params2.append("location", location);

    //       params2.append("type", type);
    //       params2.append("attachment", attachment[0]);
    //       params2.append("message", msg);
    //       const configs = {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //           Accept: "*/*",
    //         },
    //       };
    //       axios
    //         .post(server + "/webapp/file-save", params2, configs)
    //         .then((response) => {
    //           navigate("/thankyou/");
    //           console.log(response.data.message);
    //           // setMessage(response.data.message);
    //           // setLoad(false);
    //           // setShowSubmission(true);
    //         })
    //         .catch((Error) => {
    //           console.log(Error);
    //           setShowError(true);
    //           setError(
    //             "Something Went Wrong. Please try again after sometime."
    //           );
    //           setLoad(false);
    //         });
    //     }
    //   } else {
    //     setShowError(true);
    //     setError("Please Upload a PDF File Only.");
    //     setLoad(false);
    //   }
    // } else {
    params.append("firstName", firstName);
    params.append("lastName", phone);
    params.append("email", email);
    params.append("type", type);
    params.append("message", msg);
    axios
      .post(server + "/webapp/file-save", params, configs1)
      .then((response) => {
        console.log(response.data.message);
        // setMessage(response.data.message);
        setLoad(false);
        navigate("/thankyou/");

        // setShowSubmission(true);
      })
      .catch((Error) => {
        console.log(Error);
        setShowError(true);
        setError("Something Went Wrong. Please try again after sometime.");
        setLoad(false);
      });
    // }
  };
  return (
    <>
      <div className="contact-container para">
        <h1 className="contact-title heading margin-0">Ask Goken</h1>
        <p className="contact-subtitle para margin-0">
          Get in touch with us to learn more about our services or career
          opportunities. We're here to help you achieve your goals!
        </p>
      </div>
      <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <label className="contact-radio-label para semi-para fw-400">
          Please choose a category of your inquiry
        </label>

        <div className="contact-radio-group">
          <div>
            <input
              type="radio"
              name="category"
              value="General Inquiry"
              id="general"
              checked={type === "General Inquiry"}
              onChange={(e) => {
                setType(e.target.value);
              }}
            />
            <label className="para fw-400" htmlFor="general">
              General
            </label>
          </div>
          <div>
            <input
              type="radio"
              name="category"
              value="Product Or Service Inquiry"
              id="product"
              className="para"
              onChange={(e) => {
                setType(e.target.value);
              }}
              checked={type === "Product Or Service Inquiry"}
            />
            <label htmlFor="product" className="para fw-400">
              {" "}
              Product / Service
            </label>
          </div>
        </div>

        <label htmlFor="name" className="contact-label semi-para">
          Your Full Name*
        </label>
        <input
          type="text"
          id="name"
          name="name"
          required
          className="contact-input fw-300 para"
          {...register("firstName", {
            required: true,
            maxLength: 50,
          })}
        />

        <label htmlFor="email" className="contact-label semi-para">
          Email Address*
        </label>
        <input
          type="email"
          id="email"
          name="email"
          required
          className="contact-input fw-300 para"
          {...register("email", { required: true })}
        />

        <label htmlFor="phone" className="contact-label semi-para">
          Phone Number (optional)
        </label>
        <input
          type="phone"
          id="phone"
          name="phone"
          className="contact-input fw-300 para"
          {...register("phone", {
            required: false,
            maxLength: 50,
          })}
        />

        <label htmlFor="message" className="contact-label semi-para">
          Message*
        </label>
        <textarea
          id="message"
          name="message"
          required
          className="contact-textarea"
          rows={4}
          {...register("msg", { required: true })}
        ></textarea>

        <button type="submit" className="contact-button semi-title">
          Submit {load && <div className="loader"></div>}
        </button>
      </form>

      <div className="bottom-section">
        <div className="bottom-section-item br-right-white">
          <h2 className="bottom-section-title heading titlecase-text ">
            Want To Be Part Of Our<br></br> Awesome Team?
          </h2>

          <button className="bottom-section-button para fw-700 wipe-btn wipe lft-rgt-wipe">
            <Link to="/careers/" className="">
              Apply
            </Link>
          </button>
        </div>
        <div className="bottom-section-item">
          <h2 className="bottom-section-title heading titlecase-text">
            Know Someone Who Is Looking<br></br> For A Career Boost?
          </h2>
          <button className="bottom-section-button para fw-700 wipe-btn wipe lft-rgt-wipe">
            <a
              target={"_blank"}
              rel="noopener"
              href="https://hire.lever.co/referrals/new "
            >
              Refer A Friend
            </a>
          </button>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
